import { Component } from 'react';

export default class Api extends Component {
  static baseUrl = Api.getBaseUrl();
  static loginUrl = `${Api.baseUrl}users/login`;
  static registerUrl = `${Api.baseUrl}register/registerUser`;
  static orderUrl = `${Api.baseUrl}consignmentmanifest/consignmentManifestAdd`;
  static paymentStatusUrl = `${Api.baseUrl}consignmentmanifest/updatePaymentStatus`;
  static pendingPickupUrl = `${Api.baseUrl}consignmentmanifest/getPickupPendingList/`;
  static pendingDeliveryUrl = `${Api.baseUrl}consignmentmanifest/getDeliveryPendingList/`;
  static markPickupDoneUrl = `${Api.baseUrl}consignmentmanifest/markPickupDone/`;
  static markDeliveryDoneUrl = `${Api.baseUrl}consignmentmanifest/markDeliveryDone/`;
  static gettimeslotsUrl = `${Api.baseUrl}utility/gettimeslots`;
  static addAddress = `${Api.baseUrl}address/addAddress/`;
  static getCountries = `${Api.baseUrl}utility/getCountyList`;
  static getCities = `${Api.baseUrl}utility/getCityList`;
  static trackShipment = `${Api.baseUrl}consignmentmanifest/trackShipment/`;
  static getVendorAddresses = `${Api.baseUrl}vendor/getVendorAddress/`;
  static getCustomerAddresses = `${Api.baseUrl}address/getUserAddress/`;
  static addVendorAddress = `${Api.baseUrl}vendor/addVendorAddress/`;
  static getCustomer = `${Api.baseUrl}customer/getCustomerById/`;
  static getVendor = `${Api.baseUrl}vendor/getVendorById/`;
  static getAllVendors = `${Api.baseUrl}vendor/getAllVendors`;
  static registerVendor = `${Api.baseUrl}vendor/register`;
  static registerCustomer = `${Api.baseUrl}customer/register`;
  static getDeliverychargeByCity = `${Api.baseUrl}utility/getDeliverychargeByCity`;
  static verifyOtp = `${Api.baseUrl}utility/verifyOtp`;
  static getOrders = `${Api.baseUrl}users/getUserOrders/`;
  static paymentIntentUrl = `${Api.baseUrl}consignmentmanifest/payment/`;
  static pastOrders = `${Api.baseUrl}consignmentmanifest/getPastOrders/`;
  static pickupConsignments = `${Api.baseUrl}consignmentmanifest/getPickupPendingAtStore/`;
  static shippingConsignments = `${Api.baseUrl}consignmentmanifest/getShippingList/`;
  static getShipmentDetails = `${Api.baseUrl}consignmentmanifest/getShipmentDetails`;
  static getTrackOrders = `${Api.baseUrl}consignmentmanifest/userShipmentDetails/`;
  static getAllCustomers = `${Api.baseUrl}customer/getAllCustomers`;
  static driverLoginUrl = `${Api.baseUrl}driver/login`;
  static userDashboard = `${Api.baseUrl}dashbord/getUserDashbord/`;
  static updateUserAddress = `${Api.baseUrl}address/updateUserAddress`;
  static deleteUserAddress = `${Api.baseUrl}address/deleteUserAddress/`;
  static getAddress = `${Api.baseUrl}address/getAddressById/`;
  static updateVendorAddress = `${Api.baseUrl}vendor/updateVendorAddress`;
  static deleteVendorAddress = `${Api.baseUrl}vendor/delteVendorAddress/`;
  static getVendorAddress = `${Api.baseUrl}vendor/getVendorAddressById/`;
  static driverAvailability = `${Api.baseUrl}driver/changeAvailability`;
  static getDeliveryRadius = `${Api.baseUrl}utility/getDeliveryRadius`;
  static setDriverLocation = `${Api.baseUrl}driver/fieldlocationtrail`;
  static forgetPassword = `${Api.baseUrl}users/forgotpassword`;
  static updatePassword = `${Api.baseUrl}users/updatepassword`;
  static setfirebasetoken = `${Api.baseUrl}utility/setfirebasetoken`;
  static getPaymentDetails = `${Api.baseUrl}consignmentmanifest/getPaymentDetails/`;
  static getVendorByCity = `${Api.baseUrl}vendor/getAllVendorsByCity`;
  static getCustomerByCity = `${Api.baseUrl}customer/getAllCustomersByCity`;
  static editVendor = `${Api.baseUrl}vendor/editVendor`;
  static editCustomer = `${Api.baseUrl}customer/editcustomer`;
  static getDriver = `${Api.baseUrl}driver/getDriverById/`;
  static editDriver = `${Api.baseUrl}driver/editDriver/`;
  static getPreviousStore = `${Api.baseUrl}customer/getPreviousStore/`;
  static driverEarning = `${Api.baseUrl}driver/getDriverEarning/`;
  static contactusUrl = `${Api.baseUrl}utility/addcontactus`;
  static getCustomerByOrder = `${Api.baseUrl}customer/getAllCustomersByOrder`;
  static addBankDetails = `${Api.baseUrl}utility/addbankinfo`;
  static getBankDetails = `${Api.baseUrl}utility/getBankInfoByUserId`;
  static getEarnings = `${Api.baseUrl}vendor/getMyEarnings`;
  static getVendorWeeklyEarnings = `${Api.baseUrl}vendor/getMyEarningsWeekly`;
  static guestTrackShipment = `${Api.baseUrl}consignmentmanifest/guestShipmentDetails/`;
  static driverBooking = `${Api.baseUrl}driver/getBooking`;
  static driverWeeklyEarningReport = `${Api.baseUrl}driver/getWeeklyEarningReport`;
  static vendorBooking = `${Api.baseUrl}vendor/getBooking`;
  static vendorDashboard = `${Api.baseUrl}dashbord/getVendorDashbord`;
  static vendorWeeklyEarningReport = `${Api.baseUrl}vendor/getWeeklyEarningReport`;
  static checkDeliveryForArea = `${Api.baseUrl}utility/checkDeliveryForArea`;
  static getCityById = `${Api.baseUrl}utility/getCityById`;
  static getStoreUsers = `${Api.baseUrl}vendor/getUsersForStore/`;
  static addVendorForStore = `${Api.baseUrl}vendor/addNewVendorForStore`;
  static editVendorForStore = `${Api.baseUrl}vendor/editVendorForStore/`;
  static getUserStore = `${Api.baseUrl}vendor/getUserStore/`;
  static deleteVendorForStore = `${Api.baseUrl}vendor/deleteVendorForStore/`;
  static pendingVendorBooking = `${Api.baseUrl}vendor/getVendorBookingApproval/`;
  static vendorOrderApproval = `${Api.baseUrl}consignmentmanifest/vendorOrderApproval/`;

  constructor(props) {
    super(props);
    this.state = {};
    this.environment = 'dev';
    this.getBaseUrl = this.getBaseUrl.bind(this);
  }

  // Get base URL of APIs
  static getBaseUrl() {
    let url = '';
    switch (this.environment) {
      case 'production':
        url = '';
        break;
      // Default: development server
      default:
        // Stagin url
        url = 'https://85brgtkdy3.execute-api.ca-central-1.amazonaws.com/dev/api/';
        // Live url
        // url = 'https://kso911i80f.execute-api.us-east-1.amazonaws.com/dev/api/';
        // url = 'http://pickupanddeliveryapi.reviewtestlink.com:3030/api/';
        break;
    }
    return url;
  }

  environment;
}
